//import axios from "axios";

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value
  } else if (Array.from) {
    return Array.from(value)
  } else {
    return [].slice.call(value)
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function createBlankUploadsElement(source){

  if(document.getElementsByClassName("uploads-cache").length <= 1) {
    let blankElement = document.createElement("input")
    blankElement.type = "hidden"
    blankElement.name = "article[destroy_all_attachments]"
    blankElement.value = true
    document.getElementsByClassName("dropzone-default")[0].append(blankElement)
  }

  return true;
}
// export function requestServerDelete(file){
//   const signed_id = file.signed_id;

//   axios({
//     url: `/delete_blob_by_signature/${signed_id}`,
//     method: "DELETE",
//     data: {authenticity_token: $('[name="csrf-token"]')[0].content}
//   })
//   .then(res => console.log(res))

//   return true
// }